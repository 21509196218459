import Bus from '@/commons/utils/Bus';

const bus = new Bus();

export enum Messages {
    LoadingStarted = 'loading-started',
    LoadingFinished = 'loading-finished',
}

export default bus;
