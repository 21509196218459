import { setMessageKeyToValue } from '@/commons/utils/messageKeyToValue';

enum MessageBus {
  PlanClicked,
  UsePrimaryMemberInformationClicked,
  PaymentInfoAdded,
  PaymentConfirmed,
  LogoClicked,
  LanguageSelectorClicked,
  LoginSignupClicked,
  TermsClicked,
  HelpDeskMailClicked,
  ChangeLanguageAndCurrencyConfirmed,
  LoginSuccessed,
  LogoutClicked,
  RegistrationSuccessed,
  FormSubmitted,
}

const Message = setMessageKeyToValue(MessageBus);

export default Message;
