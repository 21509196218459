import notification from './notification';
import appStatusBus, { Messages as AppStatusMessages } from '@/commons/buses/appStatusBus';

// TODO move from global
import Axios from 'axios';
Axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN'
Axios.defaults.xsrfCookieName = 'XSRF-TOKEN'

// mutable values, data() will return a reference to this object so it's reactive in every component
const appStatus = {
  isLoading: false,
  loaders: {}
};

export default {
  mixins: [notification],
  propsData: { ...window.CVO },
  data() {
    return {
      // legacy isLoading flag, for new developments use "loading" computed flag
      isLoading: false,
      appStatus,
    };
  },
  created() {
    appStatusBus.subscribeOnce(AppStatusMessages.LoadingStarted, this.showLoader.bind(this));
    appStatusBus.subscribeOnce(AppStatusMessages.LoadingFinished, this.hideLoader.bind(this));
  },
  computed: {
    loading() {
      return this.appStatus.isLoading;
    },
  },
  methods: {
    showLoader() {
      this.appStatus.isLoading = true;
    },

    hideLoader() {
      this.appStatus.isLoading = false;
      this.isLoading = false;
    },

    toggleAnyLoader() {
      const loaders = Object.values(this.loaders)

      loaders.some(loader => loader === true) ? this.showLoader() : this.hideLoader();
    },

    openLinkNewTab(link) {
      window.open(link, '_blank');
    },

    redirectTo(url, event, allowGoBack = true) {
      if (!url) {
        return;
      }

      allowGoBack ? window.location.assign(url) : window.location.replace(url);
    },

    scrollTo(elementId) {
      document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
