import Bus from '@/commons/utils/Bus';
import Message from '@/jazeera/subscriptions/buses/userActionsMessages';
import UserActionsPublisherService from '@/commons/services/UserActionsPublisherService';
import getPageName from '@/commons/utils/pageName';
import pagesMap from '@/jazeera/subscriptions/constants/pagesMap';

const userActionsBus = new Bus();

new UserActionsPublisherService({
  bus: userActionsBus,
  Message,
});

export default {
  methods: {
    publishAction(message, detail) {
      const finalDetail = this.obtainFinalDetail(detail)
      try {
        userActionsBus.publish(message, finalDetail);
      } catch (err) {
        console.error(`Error publishing message to bus ${message}: [${finalDetail}]`);
      }
    },
    obtainFinalDetail(detail) {
      if (detail?.has_site_section) {
        const { has_site_section, ...finalDetail } = detail;
        finalDetail.site_section = getPageName(pagesMap);
        detail = finalDetail;
      }
      return detail
    }
  },
}



