import ApiError from './ApiError';

export default class ApiClient {
  emptyBodyCodes = [201, 204, 205, 304];
  errorStatusCodes = [400, 500];

  constructor(host, endpoint, customHeaders = {}) {
    this.headers = {
      'Content-Type': 'application/json',
      ...customHeaders
    };
    this.baseUrl = endpoint ? `${host}/${endpoint}` : host;
  }

  async get(path, queryParams = {}) {
    return this._request(path, {}, false, queryParams);
  }

  async post(path, payload) {
    return this._request(path, { method: 'POST', body: JSON.stringify(payload) });
  }

  async put(path, payload) {
    return this._request(path, { method: 'PUT', body: JSON.stringify(payload) });
  }

  async delete(path) {
    return this._request(path, { method: 'DELETE' });
  }

  async getFile(path) {
    return this._request(path, {}, true);
  }

  /**
   * @param {String} path
   * @param {RequestInit} init
   * @param {Boolean} isBlob
   */
  async _request(path, init = {}, isBlob = false, queryParams) {
    const url = this._getFullUrl(path, queryParams);
    const response = await fetch(url, { headers: this.headers, ...init });
    const formattedResponse = await this._formatResponse(response, isBlob);

    if (this._hasRedirect(formattedResponse)) {
      return this._followRedirect(formattedResponse);
    }

    const error = this._getError({ ...formattedResponse });

    return { ...formattedResponse, error };
  }

  _followRedirect(response) {
    const redirectUri = response.data.redirectUri[0];
    const redirectApiClient = new ApiClient(redirectUri, null, this.headers);

    return redirectApiClient._request('', { method: 'GET' });
  }

  _hasRedirect(response) {
    if (!response.data) {
      return false;
    }

    const { redirectCode, redirectUri } = response.data;
    if (!redirectCode || !redirectUri) {
      return false;
    }

    return redirectCode >= 300 && redirectCode < 400 && redirectUri.length > 0;
  }

  _getFullUrl(path, queryParams) {
    let fullUrl = this.baseUrl;
    
    if (path?.length) {
      fullUrl = `${fullUrl}/${path}`;
    }

    fullUrl = this._getQueryParamsUrl(fullUrl, queryParams);

    return fullUrl;
  }

  _getQueryParamsUrl(url, queryParams) {
    const hasQueryParams = queryParams && Boolean(Object.keys(queryParams)?.length);
    const fullUrl = hasQueryParams
      ? `${url}?${new URLSearchParams(queryParams)}`
      : url;

    return fullUrl;
  }

  /**
   * @param {Response} response
   * @param {Boolean} isBlob
   */
  async _formatResponse(response, isBlob = false) {
    const isStatusFamily1xx = Math.round(response.status / 100) === 1;
    const isOtherStatusWithoutBody = this.emptyBodyCodes.includes(response.status);
    const hasBody = !(isStatusFamily1xx || isOtherStatusWithoutBody);
    let data;

    if (hasBody) {
      const contentType = response.headers.get('content-type');
      const hasJsonContentType = contentType ? contentType.includes('json') : false;

      if (hasJsonContentType) {
        data = await response.json();
      } else {
        data = isBlob ? { text: await response.blob() } : { text: await response.text() };
      }
      data = Object.keys(data).length !== 0 ? data : null;
    }

    return {
      ok: response.ok,
      failed: !response.ok,
      redirected: response.redirected,
      url: response.url,
      data: this._formatError(data, response.status),
      status: response.status
    };
  }

  _getError({ ok, status, data }) {
    if (ok) return {};

    const error = new ApiError({ status, data });

    return error;
  }

  _formatError(data, status) {
    const hasStatusDataContent = data?.content && !!status;
    const statusesIncluded = this.errorStatusCodes.includes(status);

    if(hasStatusDataContent && statusesIncluded) {
      const { tenant, content, ...restData } = data.content; 

      data = { ...restData, content: tenant };
    }

    return data;
  }
}
