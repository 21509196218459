import ApiService from '@/commons/services/ApiService';
import { ViewResource } from '@/commons/utils/ViewResource';
import { Resource } from '@/commons/utils/Resource';

export const viewResources = {
  add(resourceName: string): ViewResource {
    this[resourceName] = ViewResource.create();
    return this[resourceName];
  },

  addGeneric(resourceName: string): Resource {
    this[resourceName] = Resource.create();
    return this[resourceName];
  },

  get<T extends Resource | ViewResource>(resourceName: string): T {
    return this[resourceName];
  },

  getAll(): Array<Resource | ViewResource> {
    const resources = this.filter(
      (item: Resource | ViewResource) => typeof item !== 'function'
    );

    return resources;
  },

  //WIP management of resources retrieval from get api request
  async load(viewName: string, endpoint: string = ''): Promise<void> {
    const baseUrl = `${this.get('url').get(viewName)}`;
    const headers = {
      'accept': 'application/vnd.cvo.subs.frontend+json'
    };

    const apiService = new ApiService({ baseUrl, headers });
    try {
      const response = await apiService.client.get(endpoint);

      if (response.failed) {
        console.log('Load resource failed')
      }

      this.addGeneric('tenant').setValues(response.data.tenant)
      this.add(viewName).setContents(response.data.content)
    } catch(e) {
      console.log('Error loading resource')
    }
  }
};
