// Make a hidden form and submit it

function makeField(params, key, index)
{
  const hiddenField = document.createElement('input');
  hiddenField.type = 'hidden';
  hiddenField.name = index >= 0 ? (key + '[]') : key;
  hiddenField.value = index >= 0 ? params[key][index] : params[key];
  return hiddenField;
}

export function submitForm (path, params, method = 'post') {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      if (Array.isArray(params[key])) {
        let i = 0;
        while (i < params[key].length) {
          const hiddenField = makeField(params, key, i);
          i++;
          form.appendChild(hiddenField);
        }
      } else {
        const hiddenField = makeField(params, key, -1);
        form.appendChild(hiddenField);
      }
    }
  }

  document.body.appendChild(form);
  form.submit();
}
