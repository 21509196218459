import global from '@/commons/mixins/global';
import { submitForm } from '@/commons/utils/submitForm';
import navigationBus, { Messages } from "@/commons/buses/navigationBus";

const LanguageSelector = () => import(/* webpackChunkName: "language-selector" */'@/commons/components/language-selector/LanguageSelector.vue');

export default {
  mixins: [ global ],
  components: {
    LanguageSelector,
  },
  props: {
    languageSelectorProps: {
      type: Object,
      default: () => {}
    },
    userInfo: {
      type: [ Array, Object ],
      default: () => null
    },
    changeCurrencyAction: {
      type: String,
      default: ''
    },
    loginLink: {
      type: String,
      default: ''
    },
    logoutLink: {
      type: String,
      default: ''
    },
    onClickRedirURI: {
      type: String,
      default: ''
    },
    footerLinks: {
      type: Array,
      default: () => []
    },
    accountLink: {
      type: String,
      default: ''
    },
    showSubscribeButton: {
      type: Boolean,
      default: false
    },
    forceShowLogoutButton: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getUserInfo() {
      return (!!this.userInfo && !!this.userInfo.email)
        ? this.userInfo
        : null;
    },
  },

  methods: {
    handleHeaderApplyLanguage(event) {
      const url = this.changeCurrencyAction || this.changeCurrency;
      submitForm(url, {
        currencyCode: event.currency,
        localeCode: event.language
      });
    },

    handleHeaderLinkClick(event) {
      this.redirectTo(event);
    },

    handleLoginButtonClick() {
      this.redirectTo(this.loginLink);
    },

    closeSlideMenu() {
      this.$refs.headerSlideMenu.isOpen = false;
    },

    handleHeaderLoginSubmit() {
      //pending implementation
    }
  },

  created() {
    navigationBus.subscribe(Messages.ChangeView, () => this.closeSlideMenu())
  }
};
