import { PagesMap } from '@/commons/models/PagesMap';

const pagesMap: PagesMap = {
  landing: 'landing',
  plans: 'plans',
  'customize-plan': 'extras',
  register: 'register',
  'register-private': 'register-primary',
  payment: 'payment',
  'payment-success': 'confirmation',
  subPages: new Map([
    ['register', 'register-private'],
    ['plans', 'customize-plan'],
    ['wallets', 'customize-plan']
  ]),
};

export default pagesMap;