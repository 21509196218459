import { PagesMap } from "@/commons/models/PagesMap";

export default function getPageName( pages: PagesMap):string |Map<string,string> {
    const regExp = /subscriptions\/(.+)/;
    const url = window.location.href;
    const pageName = regExp.exec(url) || ['', 'landing'];
    const pageKey = pageName[1];
    const resolvedKey = obtainSubPageKey(pageKey, pages);

    return pages[resolvedKey];
}

function obtainSubPageKey(key: string, pages: PagesMap) {
    const elements = key.split('/');
    let page = elements[0];

    if (elements.length === 1) return page;

    const lastPathSegment = elements[elements.length -1];
    let result = lastPathSegment;

    if(!isPageInMap(lastPathSegment, pages)){
        result = pages.subPages?.get(page) || page;
    }

    return result;
}

function isPageInMap(string:string, map:PagesMap) {
    const keys = Object.keys(map);
    return keys.includes(string);
}

