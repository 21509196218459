import ApiClient from '@/commons/infrastructure/api/apiClient';

export default class ApiService {
  client: ApiClient;

  constructor({
    baseUrl,
    path = '',
    headers = {},
  }: {
    baseUrl: string;
    path?: string;
    headers?: object;
  }) {
    this.client = new ApiClient(baseUrl, path, headers);
  }

  checkResponse(response: {
    failed: boolean;
    redirected: boolean;
    url: string;
    data: any;
    status: number;
  }) {
    if (response.failed) {
      throw new Error('Request failed');
    }

    // Errors can come also as status: 200
    if (response.data?.errors?.default) {
      /*
       * Extract error string
       * The format coming from the MW will be like so:
       *   default: {
       *     tenant.subscriptions.error.message: [
       *       'Something weird happened',
       *     ],
       *   },
       */
      const defaultError = Object.values(
        response.data.errors.default as { [key: string]: any }
      )[0];

      const errorMessage = defaultError[0];

      throw new Error(errorMessage);
    }
  }
}
