export type Message = string;
export type Detail = { [prop: string]: any };
type Func = (message: Message, detail: Detail) => void;
interface Action {
  func: Func,
  name: string
}
type ActionsList = Map<string, Array<Action>>;

export interface DefinedBus {
  bus: Bus,
  Message: Record<string, any>
}

export default class Bus {
  actions: ActionsList;
  isDebugging: boolean;

  constructor() {
    this.actions = new Map();
    this.isDebugging = false;
  }

  public subscribe(message: Message, func: Func) {
    if (!this.actions.has(message)) { this.initializeActions(message); }

    const action = this.addAction(message, func);

    if (this.isDebugging) this.debugSubscribe(message, action);
  }

  public subscribeOnce(message: Message, func: Func) {
    if (this.actions.has(message)) return;

    this.initializeActions(message);
    const action = this.addAction(message, func);

    if (this.isDebugging) this.debugSubscribe(message, action);
  }


  public publish(message: Message, detail: Detail = {}) {
    if (!this.actions.has(message)) return;

    if (this.isDebugging) this.debugPublish(message, detail);
    this.executeActions(message, detail);
  }

  private executeActions(message: Message, detail: Detail) {
    if (!this.actions.has(message)) return;

    this.actions.get(message)?.forEach((action: Action) => {
      action.func(message, detail);
    });
  }

  private initializeActions(message: Message) {
    this.actions.set(message, []);
  }

  private addAction(message: Message, func: Func) {
    const action = {
      func: func,
      name: func.name,
    };

    this.actions.get(message)?.push(action);
    return action;
  }

  private debugSubscribe(message: Message, action: Action) {
    console.log(
      `🔔 Subscribed %c${message} %cmessage with action %c${action.name}`,
      'color: blue',
      'color: black',
      'color: green'
    )
  }

  private debugPublish(message: Message, detail: object) {
    const messageLog = `%c${message} %cmessage`
    let detailLog = ''
    if (detail) detailLog = ` with detail ${JSON.stringify(detail)}`

    console.log(
      `📢 Published ${messageLog}${detailLog}`,
      'color: blue',
      'color: black'
    )
  }
}
