import Vue from 'vue';
import 'cvo-library';
import global from '@/commons/mixins/global';
import j9Plans from '#/subscriptions/mixins/plans';
import j9Header from '#/subscriptions/mixins/j9-header';
import j9Footer from '#/subscriptions/mixins/footer';
import Review from '@/commons/components/review/Review.vue';

export default new Vue({
  el: '#cvo-vue-library',
  mixins: [global, j9Header, j9Footer, j9Plans],
  components: { Review },
  data() {
    return {
      isHeroInViewport: true,
    };
  },

  created() {
    this.setScrollListener();
  },

  mounted() {
    this.disableCurrency();
  },

  methods: {
    setScrollListener() {
      window.addEventListener("scroll", () => {
        const y = window.scrollY;
        const vh = window.innerHeight * 0.8;
        if (y >= vh) {
          this.isHeroInViewport = false;
        } else {
          this.isHeroInViewport = true;
        }
      });
    }
  }
});
