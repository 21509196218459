export class Resource {
  store: { [key: string]: any } = {};

  static create() {
    return new Resource();
  }

  get<T>(value: string): T {
    return this.store[value];
  }

  getAll() {
    return this.store;
  }

  has(value: string) {
    return Boolean(this.get(value));
  }

  setValues(newValues = {}) {
    Object.assign(this.store, newValues);

    return this.store;
  }
}
