
function setMessageKeyToValue(Message: Record<string, any>) {
  for (let key in Message) {
    if (typeof Message[key] === 'number') {
      Message[key] = key;
    }
  }

  return Message;
}

export {
  setMessageKeyToValue
}