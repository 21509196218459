import global from '@/commons/mixins/global';
import commonsHeaderMixin from '@/commons/mixins/header';
import { viewResources } from '@/commons/utils';
import Message from '@/jazeera/subscriptions/buses/userActionsMessages';
import userActionsBus from '@/jazeera/subscriptions/mixins/user-actions-bus';

export default {
  mixins: [global, commonsHeaderMixin, userActionsBus],

  data() {
    return {
      langSelectors: null,
      isHeroInViewport: false,
    };
  },

  mounted() {
    // Workaround to set "open" event on lang selector to be able to publish an user action
    // nextTick is added due this hack was failing in sandbox
    this.$nextTick(() => {
      try {
        this.langSelectors = this.$refs.header.$el
          .querySelectorAll('.CvoLanguageSelector');

        // Add event to mobile and desktop selectors
        Array.from(this.langSelectors).forEach((selector) => {
          selector.addEventListener('click', this.handleHeaderOpenLangSelector);
        });
      } catch (e) {
        console.log(e);
      }
    })
  },

  beforeUnmount() {
    // Remove event from mobile and desktop selectors
    Array.from(this.langSelectors).forEach((selector) => {
      selector.removeEventListener('click', this.handleHeaderOpenLangSelector);
    });
  },

  methods: {
    // workaround to disable currency when logged in
    disableCurrency() {
      if (!this.userInfo.email) {
        return;
      }
      const SELECTOR = 'select[name="cvo-currency-select"]';
      const selects = this.$refs.header.$el.querySelectorAll(SELECTOR);
      selects.forEach((select) => {
        select.setAttribute('disabled', 'disabled');
      });
    },

    logout(logoutLink) {
      const isPaymentComplete = !logoutLink;

      this.publishLogoutEvent(isPaymentComplete);
      this.redirectTo(this.getLogoutLink(isPaymentComplete, logoutLink));
    },

    publishLogoutEvent(isPaymentComplete) {
      if (isPaymentComplete) {
        this.publishAction(Message.LogoutClicked);
        return;
      }

      this.publishAction(Message.LogoutClicked, { has_site_section: true });
    },

    getLogoutLink(isPaymentComplete, logoutLink) {
      if (isPaymentComplete) {
        return viewResources.get('url').get('logout');
      }
      return logoutLink;
    },

    handleHeaderOpenLangSelector(event) {
      if (!event?.target || !event.target.classList.contains('is-active')) {
        return;
      }

      const detail = {
        cta_name: 'language selector',
        position: 'header',
      };

      this.publishAction(Message.LanguageSelectorClicked, detail);
    },

    handleHeaderApplyLanguage(event) {
      const detail = {
        language: event.language,
        selected_currency: event.currency,
      };

      this.publishAction(Message.ChangeLanguageAndCurrencyConfirmed, detail);

      commonsHeaderMixin.methods.handleHeaderApplyLanguage.call(this, event);
    },

    handleLogoClick(link) {
      const detail = {
        cta_name: 'logo click',
        position: 'header',
      };

      this.publishAction(Message.LogoClicked, detail);

      this.redirectTo(link);
    },

    handleLoginButtonClick() {
      const detail = {
        cta_name: 'login/signup click',
        position: 'header',
      };

      this.publishAction(Message.LoginSignupClicked, detail);

      commonsHeaderMixin.methods.handleLoginButtonClick.call(this);
    },
  },
};
