import Message from '@/jazeera/subscriptions/buses/userActionsMessages';
import userActionsBus from '@/jazeera/subscriptions/mixins/user-actions-bus';

export default {
  mixins: [ userActionsBus ],
  props: {
    plans: {
      type: Array,
      required: false,
      default: () => {}
    },
    planSelectionUrl: {
      type: String,
      required: true,
    },
    currentPlan: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      isLoading: false,
    }
  },

  methods: {
    selectPlan(planId) {
      if (!this.planSelectionUrl) return;

      this.isLoading = true;

      const url = this.planSelectionUrl.replace('PLAN_ID', planId);
      const detail = {
        plan_name: this.getPlanName(planId),
        has_site_section: true,
      };
      this.publishAction(Message.PlanClicked, detail);
      this.redirectTo(url);
    },
    getPlanName(planId) {
      return this.plans.find( plan => plan.defaultItemId === planId).title;
    }
  },
}
