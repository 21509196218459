import Vue from 'vue';
import VueI18n from 'vue-i18n';
import TranslationsService from '@/commons/services/TranslationsService';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  silentTranslationWarn: true
});

/*
  Gets the JSON translations for the current language
*/
export default async function getI18nConfig(language: string) {
  const translationsService = new TranslationsService();

  const translations = await translationsService.getTranslations(language);

  i18n.setLocaleMessage(language, translations);
  i18n.locale = language;
  return i18n;
};