import Bus from '@/commons/utils/Bus';

const bus = new Bus();

export enum Messages {
    ChangeView = 'change-view',
    SwitchView = 'switch-view', // uses "switchView" method for sections registered in navigation
}

export default bus;
