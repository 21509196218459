const isJSONString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {

    return false;
  }

  return true;
}

const isString = (str: unknown): boolean => {
  return Boolean(typeof str === 'string' || str instanceof String);
}

export {
  isJSONString,
  isString
}
