
  import Vue from 'vue';

export default Vue.extend({
  name: 'Review',
  props: {
    author: {
      type: String,
      required: false,
      default: '',
    },
    comment: {
      type: String,
      required: false,
      default: '',
    },
  },
});
