import userActionsBus from '@/jazeera/subscriptions/mixins/user-actions-bus';
import Message from '@/jazeera/subscriptions/buses/userActionsMessages';

export default {
  mixins: [ userActionsBus ],

  data() {
    return {
      helpdeskLinkElement: null,
    };
  },

  mounted() {
    this.helpdeskLinkElement = this.$refs?.footer?.querySelector('#helpdesk-email');
    this.helpdeskLinkElement?.addEventListener('click', this.handleEmailClick);
  },

  beforeUnmount() {
    this.helpdeskLinkElement?.removeEventListener('click', this.handleEmailClick);
  },

  methods: {
    handleTermsAndConditionsClick() {
      this.publishAction(Message.TermsClicked, {
        cta_name: 'terms and conditions',
        position: 'footer'
      });
    },
    handleEmailClick() {
      this.publishAction(Message.HelpDeskMailClicked, {
        cta_name: 'helpdesk email',
        position: 'footer'
      });
    },
  },
}
