import { PageContent } from '@/commons/models';

export class ViewResource {
  private contents: PageContent;
  private options: { [key: string]: any };

  constructor() {
    this.contents = {};
    this.options = {};
  }

  static create() {
    return new ViewResource();
  }

  setContents(newContents = {}) {
    this.contents = { ...this.contents, ...newContents };
    return this;
  }

  getContents() {
    return this.contents;
  }

  setOptions(newOptions = {}) {
    this.options = newOptions;
    return this;
  }

  getOptions() {
    return this.options;
  }
}
