import { PaymentStatus } from "@/commons/models";

type FilteredStatus = {
  [prop in keyof PaymentStatus]?: boolean;
};

export function filterStatus(allStatus: PaymentStatus, expectedStatus: Array<string>): FilteredStatus {
  return Object
    .keys(allStatus)
    .reduce((acc, curr: string) => {
      if (expectedStatus.includes(curr)) {
        acc[curr] = allStatus[curr as keyof PaymentStatus];
      }
      return acc;
    }, {} as {[p:string]: boolean});
}

export function getCurrentStatus(filteredStatus: FilteredStatus) {
  return Object.keys(filteredStatus).find((key) => {
    return filteredStatus[key as keyof FilteredStatus];
  }) || null;
}