export default {
  props: {
    hasNotification: {
      type: Boolean,
      default: false,
    },
    notificationType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showNotification: this.hasNotification,
    };
  },
  methods: {
    closeNotification() {
      this.showNotification = false;
    }
  }
};
