import Bus, { DefinedBus, Detail, Message } from '@/commons/utils/Bus';
import getPageName from '@/commons/utils/pageName';
import pagesMap from '@/jazeera/subscriptions/constants/pagesMap';
import { DataLayerObject } from '@gtm-support/vue2-gtm';

export default class UserActionsReporter {
  bus: Bus;
  Message: string;
  events: { [key: string]: string | Array<string> };
  trackedPageName: string | Map<string, string>;
  constructor({ bus, Message }: DefinedBus) {
    this.bus = bus;
    this.Message = Message;
    this.trackedPageName = getPageName(pagesMap)
    this.events = {};
    this.addEvents();
    this.initSubscriptions();
    this.publishPageLoaded();
  }

  private addEvents() {
    this.events = {
      'plan_click': 'PlanClicked',
      'use_primary_member_information': 'UsePrimaryMemberInformationClicked',
      'add_payment_info': 'PaymentInfoAdded',
      'purchase': 'PaymentConfirmed',
      'header_click': ['LogoClicked', 'LanguageSelectorClicked', 'LoginSignupClicked'],
      'footer_click': ['TermsClicked', 'HelpDeskMailClicked'],
      'change_language_and_currency': 'ChangeLanguageAndCurrencyConfirmed',
      'login': 'LoginSuccessed',
      'logout': 'LogoutClicked ',
      'registration_successful': 'RegistrationSuccessed',
      'form_submit': 'FormSubmitted'
    }
  }

  private initSubscriptions() {
    Object.keys(this.Message).forEach(key => {
      if (isNaN(Number(key))) {
        this.bus.subscribe(this.Message[key as keyof unknown], this.publishToGtm.bind(this));
      }
    })
  }

  private publishToGtm(message = '', detail: Detail) {
    const gtmDataLayer = window.dataLayer;
    const event = message ? { event: this.getEventName(message) } : {};
    const data = <DataLayerObject>{ ...event, ...detail };

    gtmDataLayer?.push(data);
  }

  private getEventName(message: Message) {
    return Object.keys(this.events).find(
      (eventKey: string) => {
        return this.events[eventKey].includes(message);
      });
  }

  private publishPageLoaded(): void {
    const pageName = this.trackedPageName;
    const currentLanguage = window.CVO.currentLanguage;
    if (!pageName) return;

    const detail = {
      'page_name': pageName,
      'language': currentLanguage,
      'page_path': window.location.href,
    };

    this.publishToGtm('', detail);
  }

}
