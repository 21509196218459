import { Nullable, PaymentStatus, PaymentStatuses } from "@/commons/models";

type FilteredStatus = {
  [prop in PaymentStatuses]?: boolean;
};

export function filterStatus(allStatus: PaymentStatus, expectedStatus: Array<string>): FilteredStatus {
  return Object
    .keys(allStatus)
    .reduce((acc, curr: string) => {
      if (expectedStatus.includes(curr)) {
        acc[curr] = allStatus[curr as keyof PaymentStatus];
      }
      return acc;
    }, {} as {[p:string]: boolean});
}

export function getCurrentStatus(filteredStatus: FilteredStatus): Nullable<PaymentStatuses> {
  return Object.keys(filteredStatus).find((key) => {
    return filteredStatus[key as PaymentStatuses];
  }) as PaymentStatuses || null;
}