import ApiClient from '@/commons/infrastructure/api/apiClient';
import { Resource } from '@/commons/utils/Resource';
import { viewResources } from '@/commons/utils/viewResources';

export default class TranslationsService {
  baseUrl: string;

  constructor() {
    this.baseUrl = <string>viewResources.get<Resource>('url').get('translations');
    if (!this.baseUrl) {
      throw new Error('Missing base URL for TranslationsService')
    }
  }

  async getTranslations(language: string) {
    const response = await new ApiClient(this.baseUrl).get(language);

    return response.data?.content;
  }
}
